body {
    margin: 0px;
    padding: 0px;
    font-family: Helvetica;
    background-color: #fff;
    font-size: 16px;
    color: #343434
}

a {
    color: #343434
}

h1, h2, h3, h4, h5, h6{
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 2px;
    font-weight: 300
}

.wrapper-content{
 min-height: calc(100vh - 207px)
}

@media (max-width: 576px){
    .wrapper-content{
        min-height: calc(100vh - 361px)
    }
}

@media (max-width: 507px){
    .wrapper-content{
        min-height: calc(100vh - 379px)
    }
}
